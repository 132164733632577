import * as axios from 'axios';
import React, {useEffect, useState} from 'react'


function PgCardName( pgType, cardCode ) {

    let payjoaCardCode = Array();
    
    if (pgType.indexOf("welcome") !== -1) {

        payjoaCardCode['C1'] = "삼성카드";
        payjoaCardCode['66'] = "신한카드";
        payjoaCardCode['67'] = "현대카드";
        payjoaCardCode['61'] = "BC카드";
        payjoaCardCode['62'] = "KB국민카드";
        payjoaCardCode['68'] = "롯데카드";
        payjoaCardCode['63'] = "하나(외환)카드";
        payjoaCardCode['82'] = "하나카드";
        payjoaCardCode['71'] = "NH채움카드";
        payjoaCardCode['53'] = "씨티카드";
        payjoaCardCode['73'] = "수협카드";
        payjoaCardCode['34'] = "광주카드";
        payjoaCardCode['35'] = "제주카드";
        payjoaCardCode['37'] = "전북카드";
        payjoaCardCode['75'] = "우리카드";

    } else if (pgType.indexOf("wonsung") !== -1) {

        payjoaCardCode['01'] = "비씨카드";
        payjoaCardCode['02'] = "국민카드";
        payjoaCardCode['03'] = "외환카드";
        payjoaCardCode['04'] = "삼성카드";
        payjoaCardCode['06'] = "신한카드";
        payjoaCardCode['07'] = "현대카드";
        payjoaCardCode['08'] = "롯데카드";
        payjoaCardCode['11'] = "씨티카드";
        payjoaCardCode['12'] = "농협카드";
        payjoaCardCode['13'] = "수협카드";
        payjoaCardCode['14'] = "신협카드";
        payjoaCardCode['15'] = "우리카드";
        payjoaCardCode['16'] = "하나카드";
        payjoaCardCode['21'] = "광주카드";
        payjoaCardCode['22'] = "전북카드";
        payjoaCardCode['23'] = "제주카드";
        payjoaCardCode['24'] = "산은카드";

    }else if(pgType === "payjoa"){

        // 페이조아 카드사 코드표
        payjoaCardCode['CCSU'] = "수협";
        payjoaCardCode['CCSS'] = "삼성카드";
        payjoaCardCode['CCNH'] = "NH카드";
        payjoaCardCode['CCLO'] = "롯데카드";
        payjoaCardCode['CCLG'] = "신한카드";
        payjoaCardCode['CCKM'] = "국민카드";
        payjoaCardCode['CCKJ'] = "광주은행";
        payjoaCardCode['CCKE'] = "외환은행";
        payjoaCardCode['CCJB'] = "전북은행";
        payjoaCardCode['CCHN'] = "하나SK카드";
        payjoaCardCode['CCDI'] = "현대카드";
        payjoaCardCode['CCCT'] = "씨티은행";
        payjoaCardCode['CCCJ'] = "제주은행";
        payjoaCardCode['CCBC'] = "비씨카드";
    }
    return (
        <>
            {payjoaCardCode[cardCode]}
        </>
    );
}

function PgName( pgType ) {

    let pgOut = "";
    
    if(pgType === "payjoa"){
        pgOut = "페이조아";   
    }else if(pgType === "welcomepay_keyin"){
        pgOut = "웰컴(수기)";  
    }else if(pgType === "welcomepay_online"){
        pgOut = "웰컴(온라인)";  
    }else if(pgType === "welcomepay_terminal"){
        pgOut = "웰컴(비인증)_단말기";  
    }else if (pgType === "wonsung_keyin") {
        pgOut = "원성(수기)";
    }
    return (
        <>
            {pgOut}
        </>
    );
}

function GetTransactionType(transaction_type) {
    let transaction_name = '';
    if (transaction_type === 0) {
        transaction_name = '일반';
    } else if (transaction_type === 1) {
        transaction_name = '터미널';
    }
    return transaction_name;
}

function GetPaymenttype(paymenttype, pg_group) {

    let pgOut = "";

    if (paymenttype === 1) {
        pgOut = 'Key-in';
        //if (pg_group.includes("terminal")) {
        //    pgOut = 'Terminal';
        //} else {
        //    pgOut = 'Key-in';
        //}        
    } else if (paymenttype === 2) {
        pgOut = 'SMS';
    } else if (paymenttype === 3) {
        pgOut = 'EMAIL';
    }

    return (
        <>
            {pgOut}
        </>
    );
}

function GetPayStatus(status){
    let payStatus = Array();
    
    if(status === undefined || status === ''){

        return (
            <>
                실패
            </>
        );

    }else{

        payStatus['B'] = "정산전";
        payStatus['BC'] = "정산전취소";
        payStatus['D'] = "정산보류";
        payStatus['E'] = "정산완료";
        payStatus['EC'] = "정산완료후취소";
            
        
        return (
            <>
                {payStatus[status]}
            </>
        );

    }

}

function GetPayGubun(status){
    let payStatus = Array();
    
    if(status === undefined || status === ''){

        return (
            <>
                실패
            </>
        );

    }else{

        payStatus['B'] = "승인";
        payStatus['BC'] = "취소";
        payStatus['D'] = "정산보류";
        payStatus['E'] = "승인";
        payStatus['EC'] = "취소";
            
        
        return (
            <>
                {payStatus[status]}
            </>
        );

    }

}

// 가맹점 정산
function Calculate_store(orderno, userid){

    
    const [commission, set_commission] = useState("");
    const [price, set_price] = useState("");

    let bodyPage = {
        loginId: userid
    }

    let out = "";

    axios.post('/api/store/storeInfo', bodyPage).then(function(response){
        
        //console.log(response.data.user_id)
        set_commission(response.data[0].commission);

        let bodyPage2 = {
            orderno: orderno
        }

        axios.post('/api/pay/payInfo', bodyPage2).then(function(res2){
            set_price(res2.data[0].amount);

        });
      

    });

    
    return (
        <>
            {commission} / {price}
        </>
    );
    

}




// 보류가 가능한지 확인하기
/*
같은 승인번호이면서 결제 금액 x -1 값을 가지고 있으면 취소된 내역이 있기 때문에 보류가 안되어야 함.
*/
function ChkDelayAble(authno, amount){


    const [chkAble, set_chkAble] = useState("");

    let bodyPage = {
        authno: authno,
        amount: amount
    }

    axios.post('/api/management/pay/delay_able', bodyPage).then(function(response){
        set_chkAble(response.data[0].cnts);
    });


    return chkAble;
}

async function ChkDelayAbleSync(authno, amount) {
    try {
        const response = await axios.post('/api/management/pay/delay_able', { authno: authno, amount: amount });
        return response.data[0].cnts;
    } catch (error) {
        console.error("에러 발생:", error);
        return 0;
    }
}

// 보류설정
function ChgDelay(idx){

    let bodyPage = {
        idx: idx
    }

    axios.post('/api/management/pay/chg_delay', bodyPage).then(function(response){
        
    });
}

// 보류설정 - 통합
async function ChgDelayAll(idx, transaction_type) {

    let bodyPage = {
        idx: idx,
        transaction_type: transaction_type
    }

    const response = await axios.post('/api/manage/pay_all/chg_delay', bodyPage);

    return response
}


// 보류해제
function ChgDelayOff(idx){

    let bodyPage = {
        idx: idx
    }


    axios.post('/api/management/pay/chg_delay_off', bodyPage).then(function(response){
        
    });
}

// 보류해제 - 통합
async function ChgDelayOffAll(idx, transaction_type) {

    let bodyPage = {
        idx: idx,
        transaction_type: transaction_type
    }

    const response = await axios.post('/api/manage/pay_all/chg_delay_off', bodyPage);

    return response

}


// 보류설정 가맹점
function SettlementStChk(regid){

    let bodyPage = {
        regid: regid
    }

    axios.post('/api/management/settlement/SettlementStChk', bodyPage).then(function(response){
    });
}


// 보류설정 지사
function SettlementBrChk(regid){

    let bodyPage = {
        regid: regid
    }

    axios.post('/api/management/settlement/SettlementBrChk', bodyPage).then(function(response){
    });
}

// 보류설정 총판
function SettlementDiChk(regid){

    let bodyPage = {
        regid: regid
    }

    axios.post('/api/management/settlement/SettlementDiChk', bodyPage).then(function(response){
    });
}

// 보류설정 대리점
function SettlementAgChk(regid){

    let bodyPage = {
        regid: regid
    }

    axios.post('/api/management/settlement/SettlementAgChk', bodyPage).then(function(response){
    });
}


// 은행이름 가져오기
function GetBankName(code){

    let bodyPage = {
        code: code
    }

    const [bankName, set_bankName] = useState("");

    axios.post('/api/management/bankName', bodyPage).then(function(response){
        set_bankName(response.data[0].bank_name)
    });

    return bankName;
}


function Calculate_set(idx){

    let bodyPage = {
        idx: idx
    }

    axios.post('/api/management/pay/chg_cal', bodyPage).then(function(response){
        
    });
}

function TodayCnt(regdate, card_no, authno, order_no, reg_id, idx){
    /*
    regdate : 결제일자
    card_no : 카드번호
    authno : 결제 번호 (취소된 내역들은 중복으로 체크하지 않기 위해서 확인 용도)
    order_no : 같은 주문 번호의 합으로 최종적으로 결제 내역이 있는지 확인 용도
    reg_id : 가맹점 아이디 (가맹점이 다르면 중복이 아니기 때문)
    idx : 화면에서 보여주는 행이 중복으로 표기해줄지 여부를 판단하기 위한 키값
    */


    let bodyPage = {
        regdate: regdate,
        card_no: card_no,
        reg_id: reg_id
      };
    
    // axios.post 요청의 결과값을 처리하기 위해 Promise를 반환합니다.
    return axios.post('/api/management/pay/doublePriceChk', bodyPage)
    .then(function(response) {
        if (response.data.length > 0 && response.data[0].cnts > 1) {
            return "T";
        } else {
            return "F";
        }
    })
    .catch(function(error) {
        console.error(error);
    });

}


function DoublePayChk(regdate, cardno, regid, price){
    /*
    regdate : 결제일자
    card_no : 카드번호
    reg_id : 가맹점 아이디 (가맹점이 다르면 중복이 아니기 때문)
    */

    let body = {
        user_id: regid
    };

    let bodyPage = {
        regdate: regdate,
        card_no: cardno,
        user_id: regid
    };


   
    return axios.post('/api/management/pay/doublePayChk', bodyPage)
    .then(function(response) {
        
        const limit_chk = response.data[0].limit_chk //당일 한도설정 체크 Y
        const limit_price = response.data[0].limit_price //당일 한도 설정 금액
        const month_chk = response.data[0].month_chk //월 한도설정 체크 Y
        const month_price = response.data[0].month_price //월 한도 설정 금액
        const sum_limit_price = response.data[0].sum_limit_price //현재 당일 한도 금액 SUM
        const sum_month_price = response.data[0].sum_month_price //현재 월 한도 금액 SUM
        const today_cnt = response.data[0].today_cnt //당일 중복결재 카운트
        const double_able = response.data[0].double_able //중복결재 허용여부
        /*
        console.log('limit_price : ', limit_price);
        console.log('month_price : ', month_price);
        console.log('sum_limit_price_total : ', (Number(sum_limit_price) + Number(price)));
        console.log('sum_month_price_total : ', (Number(sum_month_price) + Number(price)));
        */

        if(limit_chk === 'Y'){
            if(limit_price < (Number(sum_limit_price) + Number(price)) ){
                return Promise.resolve("E1");
            }
        }
        if(month_chk === 'Y'){
            if(month_price < (Number(sum_month_price) + Number(price)) ){
                return Promise.resolve("E2");
            }
        }

        if(double_able === 'Y'){
            if(today_cnt > 0){
                return Promise.resolve("E3");
            }
        }

        return Promise.resolve("F");

        // if (response.data.length > 0 && response.data[0].cnts > 1) {
        //     return Promise.resolve("T");
        // } else {
        //     return Promise.resolve("F");
        // }
    })
    .catch(function(error) {
        console.error(error);
    });
}

/**
 * 아이피 출력
 * @returns 
 */
const GetIp = async () => {
    return fetch("https://geolocation-db.com/json/")
    .then((res) => res.json())
    .catch((error) => {
        console.error("Error:", error);
        throw error;
    });

}

/**
 * 이름 마킹 : 홍*동
 * @param {any} name
 * @returns
 */
function MaskName(name){

    if (name.length < 2) {
        // 한 글자는 그대로 반환
        return name;
    } else {
        return name[0] + '*'.repeat(name.length - 2) + name[name.length - 1];
    }
}

/**
 * 카드 번호 마킹
 * @param {any} cardno
 * @returns
 */
function MaskCardNo(cardno) {

    let card_out_no = cardno.substr(0, cardno.length - 7) + "****" + cardno.substr(cardno.length - 3, 3);

    return card_out_no;
}

export  {
    PgCardName, 
    PgName, 
    GetPayStatus, 
    Calculate_store, 
    GetPayGubun, 
    GetPaymenttype,
    GetTransactionType,
    ChkDelayAble,
    ChkDelayAbleSync,
    ChgDelay, 
    ChgDelayAll,
    ChgDelayOff, 
    ChgDelayOffAll,
    SettlementStChk, 
    SettlementBrChk, 
    SettlementDiChk, 
    SettlementAgChk, 
    GetBankName,
    Calculate_set, 
    TodayCnt, 
    DoublePayChk, 
    GetIp,
    MaskName,
    MaskCardNo
}