/* eslint-disable react-hooks/exhaustive-deps */
import * as axios from 'axios';
import React, { useEffect, useState, useRef } from 'react'
import * as XLSX from 'xlsx'
import { Table } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../include/SiteAdminHeader";
import Footer from "../../include/SiteAdminFooter";
import PagingNew from "../../include/PagingNew";
import queryString from 'query-string';
import { PgCardName, PgName, ChkDelayAble, GetPayGubun, GetPaymenttype, GetTransactionType } from "../../include/ConfigSet";
import { maskingFunc } from "../../include/ConfigSet_masking";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/esm/locale";
import re_closebtn_w from '../../../../assets/img/common/re_closebtn_w.png';
import OpenLogComment from "../../include/OpenLogComment";
import $ from 'jquery';
import "./styles.css"; // CSS 스타일 추가

/*******************************************
 * 정산보류내역
 *******************************************/
function SiteAdminPayAllPayRseserve() {

    const navigate = useNavigate();
    const loginId = sessionStorage.getItem("loginId");

    // 운영로그 상태*****************************
    const [log_set, set_log_set] = useState(false);
    const [log_set_msg, set_log_set_msg] = useState('');
    const [pageReload, set_pageReload] = useState('');
    React.useEffect(() => {
        if (log_set === true) {
            set_log_set(false);
            set_pageReload('');
        }
    }, [log_set])
    // ******************************************

    const qs = queryString.parse(window.location.search);
    const sWord = qs.searchWord ? qs.searchWord : '';
    const page = qs.pg ? qs.pg : 1;

    $("#searchWord").val(sWord);

    const sCate = qs.searchCates ? qs.searchCates : 'St_branch_name';
    $("#searchCates").val(sCate);

    const pg_group = qs.pg_group ? qs.pg_group : '';
    $("#pg_group").val(pg_group);

    const paymenttype = qs.paymenttype ? qs.paymenttype : '';
    $("#paymenttype").val(paymenttype);

    const ordertype = qs.ordertype ? qs.ordertype : '';
    $("#ordertype").val(ordertype);

    const chkdate = qs.chkdate ? qs.chkdate : 'authdate';
    $("#chkdate").val(chkdate);

    const sdate = qs.sdate ? qs.sdate : '';
    $("#sdate").val(sdate);

    const edate = qs.edate ? qs.edate : '';
    $("#edate").val(edate);

    const status = qs.status ? qs.status : '';
    $("#status").val(status);

    const q_parent_idx1 = qs.parent_idx1 ? qs.parent_idx1 : '';
    const q_parent_idx2 = qs.parent_idx2 ? qs.parent_idx2 : '';
    const q_parent_idx3 = qs.parent_idx3 ? qs.parent_idx3 : '';

    const [parent_idx1, set_parent_idx1] = useState("");
    const [parent_idx2, set_parent_idx2] = useState("");
    const [parent_idx3, set_parent_idx3] = useState("");
    const [managerList1, setManagerList1] = useState([]);
    const [managerList2, setManagerList2] = useState([]);
    const [managerList3, setManagerList3] = useState([]);
    const on_set_parent_idx1_handler = (event) => {
        set_parent_idx1(event.currentTarget.value);
        getManagerList2(event.currentTarget.value);
    }

    const on_set_parent_idx2_handler = (event) => {
        set_parent_idx2(event.currentTarget.value);
        getManagerList3(event.currentTarget.value);
    }

    const on_set_parent_idx3_handler = (event) => {
        set_parent_idx3(event.currentTarget.value);
    }


    const tableRef = useRef(null);
    // ****************************************************
    // 그리드 영역
    // ****************************************************    
    const [tableHeight, setTableHeight] = useState(window.innerHeight - 365);
    const [contextMenu, setContextMenu] = useState({ visible: false, x: 0, y: 0, record: null });
    //const [selectedRowKeys, setSelectedRowKeys] = useState([]); // 체크된 행    
    const [listData, setListData] = useState([]);               // 테이블 데이터
    const [loading, setLoading] = useState(false);              // 로딩 상태
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [sorter, setSorter] = useState({ field: "", order: "descend" });

    const [total_amount_ex, set_total_amount_ex] = useState(0);
    const [total_pg_fee_ex, set_total_pg_fee_ex] = useState(0);
    const [total_pgImcome_ex, set_total_pgImcome_ex] = useState(0);
    const [total_st_fee_ex, set_total_st_fee_ex] = useState(0);
    const [total_cal_price_ex, set_total_cal_price_ex] = useState(0);
    const [total_calculateMbramch_ex, set_total_calculateMbramch_ex] = useState(0);
    const [total_calculateDistributor_ex, set_total_calculateDistributor_ex] = useState(0);

    const columns = [
        { title: "NO", dataIndex: "num", key: "num", align: "center", width: 50 },
        {
            title: "거래구분", dataIndex: "transaction_type", key: "transaction_type", align: "center", width: 100,
            render: (text, record) => {
                return <>
                    <span>{GetTransactionType(record.transaction_type)}</span>
                </>
            },
        },
        {
            title: "거래일", dataIndex: "created_datetime", key: "created_datetime", align: "center", width: 150,
            render: (text, record) => {
                let cancelColor = '';
                if (record.status === 'C' || record.status === 'BC' || record.status === 'EC') {
                    cancelColor = 'red';
                } else {
                    cancelColor = '';
                }
                return <>
                    <span className={`${cancelColor}`} >
                        {record.created_datetime}
                    </span>
                </>
            }
        },
        {
            title: "구분", dataIndex: "status", key: "status", align: "center", width: 150,
            render: (text, record) => {
                let cancelColor = '';
                if (record.status === 'C' || record.status === 'BC' || record.status === 'EC') {
                    cancelColor = 'red';
                } else {
                    cancelColor = '';
                }
                return <>
                    <span className={`blue_txt ${cancelColor}`} >
                        {GetPayGubun(record.status)}
                    </span>
                </>
            }

        },

        { title: "총판", dataIndex: "Di_branch_name", key: "Di_branch_name", align: "center", width: 100 },
        { title: "가맹점", dataIndex: "St_branch_name", key: "St_branch_name", align: "center", width: 100 },
        {
            title: "결제방법", dataIndex: "paymenttype", key: "paymenttype", align: "center", width: 100,
            render: (text, record) => {
                let paymenttypeTitle = '';
                if (record.transaction_type === 1) {
                    paymenttypeTitle = 'Terminal';
                } else {
                    paymenttypeTitle = GetPaymenttype(record.paymenttype, '');
                }
                return <>
                    <span>
                        {paymenttypeTitle}
                    </span>
                </>
            },
        },
        {
            title: "정산상태", dataIndex: "", key: "", align: "center", width: 110,
            render: (text, record) => {
                return <>
                    <span>취소차감전</span>
                </>
            },
        },
        {
            title: "카드사", dataIndex: "cardcode", key: "cardcode", align: "center", width: 100,
            render: (text, record) => {
                //console.log('record.cardcode : ', record.cardcode);
                return <>
                    <span>{PgCardName(record.pg_group, record.cardcode)}</span>
                </>
            },
        },
        {
            title: "할부", dataIndex: "quota", key: "quota", align: "center", width: 100,
            render: (text, record) => {
                return <>
                    <span>
                        {record.quota === "00" ? '일시불' : record.quot}
                    </span>
                </>
            },
        },
        {
            title: "카드번호", dataIndex: "cardno", key: "cardno", align: "center", width: 150,
            render: (text, record) => {
                return <>
                    <span>
                        {maskingFunc.card(record.cardno)}
                    </span>
                </>
            },
        },
        { title: "승인번호", dataIndex: "authno", key: "authno", align: "center", width: 120 },
        {
            title: "취소", dataIndex: "cancel_date", key: "cancel_date", align: "center", width: 110,
            render: (text, record) => {
                let cancel_date1 = record.cancel_date.substr(0, 10);
                if (record.cancel_date !== "") {
                    if (cancel_date1 === "0000-00-00") {
                        cancel_date1 = "";
                    }
                }
                return <span>{cancel_date1}</span>
            }
        },
        { title: "PG예정", dataIndex: "calculate_date_re", key: "calculate_date_re", align: "center", width: 100 },
        {
            title: "정산예정", dataIndex: "calculate_date_re", key: "calculate_date_re", align: "center", width: 100,
            render: (text, record) => {

                let income = 0;
                if (record.income) {
                    income = record.income;
                }

                return <>
                    <span>
                        {record.calculate_date_re} 
                    </span>&nbsp;
                    <span className="red">(D+{income.toString()})</span>
                </>
            },
        },
        {
            title: "거래금액", dataIndex: "amount", key: "amount", align: "center", width: 100,
            render: (text, record) => {
                return <>
                    <span>
                        {record.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </span>
                </>
            },
        },
        {
            title: "PG", dataIndex: "pg_fee", key: "pg_fee", align: "center", width: 100,
            render: (text, record) => {
                let pg_fee = "0";
                if (record.pg_fee) {
                    pg_fee = record.pg_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                }

                let fee = 0;
                if (record.fee) {
                    fee = record.fee;
                }

                return <>
                    <span>
                        {pg_fee}
                    </span>&nbsp;
                    <span className="red">({fee.toString()}%)</span>
                </>
            }
        },
        {
            title: "PG입금", dataIndex: "pgImcome", key: "pgImcome", align: "center", width: 100,
            render: (text, record) => {

                let pg_fee = "0";
                if (record.pg_fee) {
                    pg_fee = record.pg_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                }

                // PG 입금
                let pgImcome = parseInt(record.amount) - parseInt(pg_fee);
                return <>
                    <span>
                        {pgImcome.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </span>
                </>
            }
        },
        {
            title: "가맹점", dataIndex: "st_fee", key: "st_fee", align: "center", width: 100,
            render: (text, record) => {
                return <>
                    <span>
                        {record.st_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </span>&nbsp;
                    <span className="red">({record.feeStore.toString()}%)</span>
                </>
            }
        },
        {
            title: "정산금액", dataIndex: "st_fee", key: "st_fee", align: "center", width: 100,
            render: (text, record) => {
                let pg_fee = "0";
                if (record.pg_fee) {
                    pg_fee = record.pg_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                }
                // 정산금액 (결제금액 - PG - 가맹점)
                let cal_price = parseInt(record.amount) - parseInt(pg_fee) - parseInt(record.st_fee);

                return <>
                    <span>
                        {cal_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </span>
                   
                </>
            }
        },
        {
            title: "지사", dataIndex: "calculateMbramch", key: "calculateMbramch", align: "center", width: 150,
            render: (text, record) => {

                // 지사 정산금액 = 결제금액 / 100 * (총판 - 지사)

                let calculateMbramch = parseInt(record.amount) / 100 * ((record.feeDistributor - record.feeMbramch).toFixed(2));

                return <>
                    <span>
                        {calculateMbramch.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </span>&nbsp;
                    <span className="red">({record.feeMbramch.toString()}%)</span>
                </>
            }
        },
        {
            title: "총판", dataIndex: "calculateDistributor", key: "calculateDistributor", align: "center", width: 100,
            render: (text, record) => {

                // 총판 정산금액 = 결제금액 / 100 * (총판 - 지사)
                let calculateDistributor = parseInt(record.amount) / 100 * (record.feeAgency - record.feeDistributor);

                return <>
                    <span>
                        {calculateDistributor.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </span>&nbsp;
                    <span className="red">({record.feeDistributor.toString()}%)</span>
                </>
            }
        },
    ];

    // API 데이터 가져오기
    const fetchData = (page = 1, pageSize = pagination.pageSize, sorterField, sorterOrder) => {


        if (!sorterField) {
            sorterField = "created_datetime";
            sorterOrder = "descend";
        }

        setLoading(true);

        let bodyPage = {

            nFrom: (page - 1) * pagination.pageSize,
            gListRows: pagination.pageSize,
            searchWord: sWord,
            searchCates: sCate,
            user_id: loginId,
            pg_group: pg_group,
            paymenttype: paymenttype,
            ordertype: ordertype,
            parent_idx1: q_parent_idx1,
            parent_idx2: q_parent_idx2,
            parent_idx3: q_parent_idx3,
            status: status,
            sortField: sorterField,
            sortOrder: sorterOrder === "ascend" ? "ASC" : "DESC",
            gridMode: "COUNT"
        }

        //console.log("bodyPage : ", bodyPage);

        setLoading(true);

        let totalCount = 0;

        axios.post('/api/manage/pay_all/pay_reserve_list', bodyPage).then(function (res) {

            totalCount = res.data[0].tcnt;

            setPagination((prev) => ({
                ...prev,
                current: page,
                total: res.data[0].tcnt, // 총 데이터 개수
            }));

            bodyPage.gridMode = "";

            axios.post('/api/manage/pay_all/pay_reserve_list', bodyPage).then(function (res) {

                //console.log('res : ', res);

                let total_amount_ex = 0;
                let total_pg_fee_ex = 0;
                let total_pgImcome_ex = 0;
                let total_st_fee_ex = 0;
                let total_cal_price_ex = 0;
                let total_calculateMbramch_ex = 0;
                let total_calculateDistributor_ex = 0;

                res.data.map((v, index) => {

                    let pg_fee = 0; 
                    if (v.pg_fee) {
                        pg_fee = v.pg_fee;
                    }

                    // 거래금액 합산
                    total_amount_ex = parseInt(total_amount_ex) + parseInt(v.amount);
                    // PG금액 합산
                    total_pg_fee_ex = parseInt(total_pg_fee_ex) + parseInt(pg_fee);
                    // PG 입금 합산 금액
                    total_pgImcome_ex = parseInt(total_pgImcome_ex) + (parseInt(v.amount) - parseInt(pg_fee));
                    // 가맹점 합산 금액
                    total_st_fee_ex = parseInt(total_st_fee_ex) + parseInt(v.st_fee);

                    // 정산금액 합산
                    total_cal_price_ex = parseInt(total_cal_price_ex) + (parseInt(v.amount) - parseInt(pg_fee) - parseInt(v.st_fee));

                    // 지사금액 합산
                    total_calculateMbramch_ex = parseInt(total_calculateMbramch_ex) + (parseInt(v.amount) / 100 * (v.feeDistributor - v.feeMbramch));

                    // 총판금액 합산
                    total_calculateDistributor_ex = parseInt(total_calculateDistributor_ex) + (parseInt(v.amount) / 100 * (v.feeAgency - v.feeDistributor));
                });

                set_total_amount_ex(total_amount_ex);
                set_total_pg_fee_ex(total_pg_fee_ex);
                set_total_pgImcome_ex(total_pgImcome_ex);
                set_total_st_fee_ex(total_st_fee_ex);
                set_total_cal_price_ex(total_cal_price_ex);
                set_total_calculateMbramch_ex(total_calculateMbramch_ex);
                set_total_calculateDistributor_ex(total_calculateDistributor_ex);
                
                const formattedData = res.data.map((item, index) => ({
                    ...item,
                    num: totalCount - (page - 1) * pageSize - index, // 높은 순번 계산    
                }));

                setListData(formattedData);


            })
        })

        setLoading(false);

    };

    // 페이지 변경 이벤트 핸들러
    const handleTableChange = (pagination, filters, sorter) => {
        setSorter({ field: sorter.field, order: sorter.order });
        fetchData(pagination.current, pagination.pageSize, sorter.field, sorter.order);
    };

    // 체크박스 선택 시 실행되는 함수
    //const onSelectChange = (newSelectedRowKeys) => {
    //    //console.log("선택된 행:", newSelectedRowKeys);
    //    setSelectedRowKeys(newSelectedRowKeys);
    //};

    // 체크박스 설정
    //const rowSelection = {
    //    selectedRowKeys,
    //    onChange: onSelectChange,
    //};

    // 우클릭 시 컨텍스트 메뉴 표시
    const ContextMenu = ({ x, y, visible, onClose, record }) => {

        useEffect(() => {
            if (visible) {
                const handleClickOutside = () => onClose();
                document.addEventListener("click", handleClickOutside);
                return () => document.removeEventListener("click", handleClickOutside);
            }
        }, [visible, onClose]);

        return (
            visible && (
                <div className="context-menu" style={{ top: `${y}px`, left: `${x}px` }}>
                    <ul>
                        <li onClick={() => FnView(record.idx, record.transaction_type)} >상세보기</li>
                    </ul>
                </div>
            )
        );
    };


    /**
     * 상세보기
     * @param {any} payment_idx
     * @param {any} transaction_type
     */
    const FnView = (payment_idx, transaction_type) => {

        if (transaction_type === 0) {
            navigate('/SiteAdmin/_pay/pay_reserve_view?idx=' + payment_idx + '&transaction_type=' + transaction_type);
        } else if (transaction_type === 1) {
            navigate('/SiteAdmin/_pay_terminal/pay_reserve_view?idx=' + payment_idx + '&transaction_type=' + transaction_type);
        }
    }

    const showContextMenu = (event, record) => {
        event.preventDefault();
        setContextMenu({
            visible: true,
            x: event.clientX, // 마우스 커서의 X 좌표
            y: event.clientY, // 마우스 커서의 Y 좌표
            record,
        });
    };

    // 컴포넌트 마운트 시 첫 데이터 로드
    useEffect(() => {
        fetchData(pagination.current, pagination.pageSize, sorter.field, sorter.order);
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setTableHeight(window.innerHeight - 200); // 200px 여백 고려
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // ****************************************************


    function getManagerList1() {

        let bodyPage = {
            user_level: 2,
        }
        axios.post('/api/management/manager_list', bodyPage).then(function (res) {
            setManagerList1(res.data);

        })
    }

    function getManagerList2(temp_idx) {
        let bodyPage = {
            user_level: 3,
            parent_idx: temp_idx,
        }
        axios.post('/api/management/distributor_sel_list', bodyPage).then(function (res) {
            setManagerList2(res.data);
        })
        if (q_parent_idx2 !== "") {
            set_parent_idx2(q_parent_idx2)
        }
    }

    function getManagerList3(temp_idx) {
        let bodyPage = {
            user_level: 5,
            parent_idx: temp_idx,
        }
        axios.post('/api/management/store_sel_list', bodyPage).then(function (res) {
            setManagerList3(res.data);
        })
        if (q_parent_idx3 !== "") {
            set_parent_idx3(q_parent_idx3)
        }
    }

    function rendManagerList() {
        return (
            <select name="parent_idx1" id="parent_idx1" onChange={on_set_parent_idx1_handler} value={parent_idx1}  >
                <option value=''>지사 선택</option>
                {
                    managerList1.map((v, index) => {
                        return (
                            <option value={v.idx} key={v.idx} >{v.branch_name}</option>
                        )
                    })
                }
            </select>
        )
    }

    function rendManagerList2() {
        return (
            <select name="parent_idx2" id="parent_idx2" onChange={on_set_parent_idx2_handler} value={parent_idx2} >
                <option value=''>총판 선택</option>
                {
                    managerList2.map((v, index) => {
                        return (
                            <option value={v.idx} key={v.idx} >{v.branch_name}</option>
                        )
                    })
                }
            </select>
        )
    }

    function rendManagerList3() {
        return (
            <select name="parent_idx3" id="parent_idx3" onChange={on_set_parent_idx3_handler} value={parent_idx3} >
                <option value=''>가맹점 선택</option>
                {
                    managerList3.map((v, index) => {
                        return (
                            <option value={v.idx} key={v.idx} >{v.branch_name}</option>
                        )
                    })
                }
            </select>
        )
    }

    /**
     * 엑셀다운로드
     */
    const handleDownload = () => {

        if (!tableRef.current) return;

        // 1. div 안의 HTML을 가져와 동적으로 테이블 생성
        const table = document.createElement("table");
        table.innerHTML = tableRef.current.innerHTML;

        // 2. 엑셀 워크시트 변환
        const ws = XLSX.utils.table_to_sheet(table);

        // 3. 엑셀 워크북 생성 및 데이터 추가
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "DIV 데이터");

        // 4. 파일 다운로드
        XLSX.writeFile(wb, "정산보류내역.xlsx");

        set_log_set_msg('엑셀다운로드');
        set_log_set(true);

    };

    function pop_close() {
        $('.pop_common').removeClass('show');
    }

    const fn_submit = (e) => {
        $("#pg").val("1");
    }

    useEffect(() => {
        getManagerList1();
    }, [])


    useEffect(() => {
        set_parent_idx1(q_parent_idx1);
    }, [q_parent_idx1]);

    useEffect(() => {
        set_parent_idx2(q_parent_idx2);
    }, [q_parent_idx2]);

    useEffect(() => {
        set_parent_idx3(q_parent_idx3);
    }, [q_parent_idx3]);


    // 총판 내역 가져오기
    useEffect(() => {
        getManagerList2(q_parent_idx1);
    }, [managerList1])

    // 총판 내역 가져오기
    useEffect(() => {
        getManagerList3(q_parent_idx2);
    }, [managerList2])

    return (
        <>

            {(log_set === true) && (<OpenLogComment pageMode={`거래조회 > 정산보류내역 > ${log_set_msg}`} pageReload={pageReload} />)} {/* 운영로그 기록 */}

            <div id="wrap">
                <Header />


                <div id="container" data-menu-page="pay_deduction">
                    <h3 className="page_ttl">정산보류내역</h3>

                    <div style={{ width: '100%', height: '42px' }}>
                        <div style={{ float: 'left' }}>
                            <form name="" id="" action="./pay_reserve" method="get" onSubmit={fn_submit} >
                                <input type="hidden" name="pg" id="pg" value={page} />

                                <div className="top_filter">

                                  

                                    <div className="sel_box">
                                        {
                                            rendManagerList()
                                        }
                                    </div>

                                    <div className="sel_box">
                                        {
                                            rendManagerList2()
                                        }
                                    </div>

                                    <div className="sel_box">
                                        {
                                            rendManagerList3()
                                        }
                                    </div>


                                    <div className="sel_box">
                                        <select name="searchCates" id="searchCates">
                                            <option value="St_branch_name">가맹점명</option>
                                            <option value="Br_branch_name">지사명</option>
                                            <option value="Di_branch_name">총판명</option>
                                            <option value="authno">승인번호</option>
                                            <option value="cardno">카드번호(뒤 4자리)</option>
                                            <option value="amount">결제금액</option>
                                            <option value="orderno">주문번호</option>
                                        </select>
                                    </div>

                                    <div className="in_box search_box">
                                        <input type="text" name="searchWord" id="searchWord" placeholder='검색해주세요' />
                                        <button type="submit" className="btn_st_02 blue search">검색</button>
                                    </div>


                                </div>

                            </form>
                        </div>
                        <div className="btn_box" style={{ float: 'left', marginTop: '4px', marginLeft: '15px' }}>
                            <button className="els_btn" onClick={handleDownload}><i></i>엑셀 다운로드</button>
                        </div>
                    </div>


                    <div className="bot_item" >
                        <div className="bot_info">
                            <div className="left">총 {pagination.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}개</div>
                        </div>

                        <div className="table_box">

                            <div ref={tableRef} style={{ position: "relative" }}>
                                <Table

                                    columns={columns}
                                    dataSource={listData}
                                    onRow={(record) => ({
                                        onContextMenu: (event) => showContextMenu(event, record),
                                    })}


                                    rowKey="idx"
                                    //rowSelection={rowSelection} // 체크박스 적용

                                    pagination={{
                                        current: pagination.current,
                                        pageSize: pagination.pageSize,
                                        total: pagination.total,
                                    }}
                                    loading={loading}
                                    onChange={handleTableChange}
                                    scroll={{ y: tableHeight }}

                                    summary={(pageData) => {
                                        //console.log('pageData : ', pageData);

                                        return (
                                            <Table.Summary.Row>
                                                <Table.Summary.Cell colSpan={15}>
                                                    <strong>TOTAL</strong>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell>
                                                    <strong>{total_amount_ex.toLocaleString()}</strong>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell >
                                                    <strong>{total_pg_fee_ex.toLocaleString()}</strong>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell >
                                                    <strong>{total_pgImcome_ex.toLocaleString()}</strong>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell >
                                                    <strong>{total_st_fee_ex.toLocaleString()}</strong>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell>
                                                    <strong>{total_cal_price_ex.toLocaleString()}</strong>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell>
                                                    <strong>{total_calculateMbramch_ex.toLocaleString()}</strong>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell>
                                                    <strong>{total_calculateDistributor_ex.toLocaleString()}</strong>
                                                </Table.Summary.Cell>

                                             

                                            </Table.Summary.Row>
                                        );
                                    }}
                                />

                                <ContextMenu
                                    x={contextMenu.x}
                                    y={contextMenu.y}
                                    visible={contextMenu.visible}
                                    onClose={() => setContextMenu({ ...contextMenu, visible: false })}
                                    record={contextMenu.record} // 선택된 행의 데이터 전달
                                />
                            </div>

                        </div>


                    </div>


                   
                </div>
            </div>

            <Footer />
        </>
    )
}

export default SiteAdminPayAllPayRseserve