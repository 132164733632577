import * as axios from 'axios';
import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from "react-router-dom";
import { Table, Empty } from "antd";
import Header from "../../include/SiteAdminHeader";
import Footer from "../../include/SiteAdminFooter";

import queryString from 'query-string';
import { PgCardName, PgName, GetPaymenttype } from "../../include/ConfigSet";
import { maskingFunc } from "../../include/ConfigSet_masking";
import $ from 'jquery';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/esm/locale";
import "./styles.css"; // CSS 스타일 추가

function SiteAdminPayPayErrorList() {

    const navigate = useNavigate();

    const qs = queryString.parse(window.location.search);
    const page = qs.pg ? qs.pg : 1;  
    const sWord = qs.searchWord ? qs.searchWord : '';
    $("#searchWord").val(sWord);

    const sCate = qs.searchCates ? qs.searchCates : 'amount';
    $("#searchCates").val(sCate);

    const pg_group = qs.pg_group ? qs.pg_group : '';
    $("#pg_group").val(pg_group);

    const paymenttype = qs.paymenttype ? qs.paymenttype : '';
    $("#paymenttype").val(paymenttype);

    const ordertype = qs.ordertype ? qs.ordertype : '';
    $("#ordertype").val(ordertype);

    const chkdate = qs.chkdate ? qs.chkdate : 'authdate';
    $("#chkdate").val(chkdate);

    const sdate = qs.sdate ? qs.sdate : '';
    $("#sdate").val(sdate);

    const edate = qs.edate ? qs.edate : '';
    $("#edate").val(edate);

    const q_parent_idx1 = qs.parent_idx1 ? qs.parent_idx1 : '';

    const status = qs.status ? qs.status : '';
    $("#status").val(status);

    const loginId = sessionStorage.getItem("loginId");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [managerList1, setManagerList1] = useState([]);
    const [parent_idx1, set_parent_idx1] = useState("");

    const on_set_parent_idx1_handler = (event) => {
        set_parent_idx1(event.currentTarget.value);
    }

    const tableRef = useRef(null);
    // ****************************************************
    // 그리드 영역
    // ****************************************************    
    const [tableHeight, setTableHeight] = useState(window.innerHeight - 365);
    const [contextMenu, setContextMenu] = useState({ visible: false, x: 0, y: 0, record: null });   
    const [listData, setListData] = useState([]);               // 테이블 데이터
    const [loading, setLoading] = useState(false);              // 로딩 상태
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [sorter, setSorter] = useState({ field: "", order: "descend" });

    const columns = [
        { title: "NO", dataIndex: "num", key: "num", align: "center" },
        {
            title: "거래일자", dataIndex: "p.created_datetime", key: "p.created_datetime", align: "center", width: 150, 
            render: (text, record) => {               
                return <>
                    <span>
                        {record.created_datetime}
                    </span>
                </>
            },
        },
        {
            title: "구분", dataIndex: "status", key: "status", align: "center", width: 80,          
            render: (text, record) => {
                return <>
                    <span>
                        실패
                    </span>
                </>
            },
        },
        { title: "ErrCode", dataIndex: "resultcode", key: "resultcode", align: "center", width: 100 },
        { title: "Description", dataIndex: "errormessage", key: "errormessage", align: "center", width: 150 },
        { title: "대리점", dataIndex: "Ag_branch_name", key: "Ag_branch_name", align: "center", width: 100 },
        { title: "가맹점", dataIndex: "St_branch_name", key: "St_branch_name", align: "center", width: 150 },
        { title: "고객명", dataIndex: "username", key: "username", align: "center", width: 100 },
        {
            title: "상품명", dataIndex: "productname", key: "productname", align: "center", width: 150,
            render: (text, record) => {
                return (text)
            },
        },
        {
            title: "결제방법", dataIndex: "paymenttype", key: "paymenttype", align: "center", width: 100,
            render: (text, record) => {
                return <>{GetPaymenttype(record.paymenttype)}</>
            },   
        },
        {
            title: "카드사", dataIndex: "cardcode", key: "cardcode", align: "center", width: 150,
            render: (text, record) => {
                return <>
                    <span>
                        {PgCardName(record.pg_group, record.cardcode)}
                    </span>
                </>
            },
        },
        {
            title: "할부", dataIndex: "quota", key: "quota", align: "center", width: 80,
            render: (text, record) => {
                return <>
                    <span>
                        {record.quota === "00" ? '일시불' : record.quot}
                    </span>
                </>
            },
        },
        {
            title: "카드번호", dataIndex: "cardno", key: "cardno", align: "center", width: 100,
            render: (text, record) => {
                return <>
                    <span>
                        {maskingFunc.card(record.cardno)}
                    </span>
                </>
            },
        },
        { title: "승인번호", dataIndex: "authno", key: "authno", align: "center", width: 150 },
        {
            title: "거래금액", dataIndex: "amount", key: "amount", align: "center", width: 150,
            render: (text, record) => {
                return <>
                    <span>
                        {record.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </span>
                </>
            },
        },
        {
            title: "PG", dataIndex: "pg_group", key: "pg_group", align: "center", width: 150,
            render: (text, record) => {
                return <>
                    <span className={`pg_name_td`} >
                        {PgName(record.pg_group)}
                    </span>
                </>
            },
        },        
        { title: "지사", dataIndex: "Br_branch_name", key: "Br_branch_name", align: "center", width: 150 },       
        { title: "TID", dataIndex: "cpid", key: "cpid", align: "center", width: 150 },        
        { title: "총판", dataIndex: "Di_branch_name", key: "Di_branch_name", align: "center", width: 150 },
        { title: "주문번호", dataIndex: "orderno", key: "orderno", align: "center", width: 120 },
        { title: "상점코드", dataIndex: "cpid", key: "cpid", align: "center", width: 100 },             
    ];


    // API 데이터 가져오기
    const fetchData = (page = 1, pageSize = pagination.pageSize, sorterField, sorterOrder) => {


        if (!sorterField) {
            sorterField = "created_datetime";
            sorterOrder = "descend";
        }

        //console.log("sorterField : ", sorterField);
        //console.log("sorterOrder : ", sorterOrder);

        setLoading(true);

        let bodyPage = {
            nFrom: (page - 1) * pagination.pageSize,
            gListRows: pagination.pageSize,                 
            searchWord: sWord,
            searchCates: sCate,
            user_id: loginId,
            pg_group: pg_group,
            paymenttype: paymenttype,
            ordertype: ordertype,
            chkdate: chkdate,
            sdate: sdate,
            edate: edate,
            parent_idx1: q_parent_idx1,
            status: status,
            sortField: sorterField,
            sortOrder: sorterOrder === "ascend" ? "ASC" : "DESC",
        }

        console.log("bodyPage : ", bodyPage);


        setLoading(true);

        let totalCount = 0;

        axios.post('/api/manage/pay_all/pay_error_total_list', bodyPage).then(function (res) {

            totalCount = res.data.tcnt;

            setPagination((prev) => ({
                ...prev,
                current: page,
                total: res.data.tcnt, // 총 데이터 개수
            }));

            axios.post('/api/manage/pay_all/pay_error_list', bodyPage).then(function (res) {

                //console.log('res : ', res);
               
                const formattedData = res.data.map((item, index) => ({
                    ...item,
                    num: totalCount - (page - 1) * pageSize - index, // 높은 순번 계산    
                    //status: GetPayGubun(item.status)
                    //created_datetime: item.created_datetime.substr(0, 10) + ' ' + item.created_datetime.substr(11, 8)
                }));

                setListData(formattedData);

            })
        })

        setLoading(false);

    };

    // 페이지 변경 이벤트 핸들러
    const handleTableChange = (pagination, filters, sorter) => {
        setSorter({ field: sorter.field, order: sorter.order });
        fetchData(pagination.current, pagination.pageSize, sorter.field, sorter.order);
    };
  
    // 우클릭 시 컨텍스트 메뉴 표시
    const ContextMenu = ({ x, y, visible, onClose, record }) => {

        useEffect(() => {
            if (visible) {
                const handleClickOutside = () => onClose();
                document.addEventListener("click", handleClickOutside);
                return () => document.removeEventListener("click", handleClickOutside);
            }
        }, [visible, onClose]);

        return (
            visible && (
                <div className="context-menu" style={{ top: `${y}px`, left: `${x}px` }}>
                    <ul>                        
                        <li onClick={() => FnView(record.idx, record.transaction_type)} >상세보기</li>                   
                    </ul>
                </div>
            )
        );
    };


    /**
     * 상세보기
     * @param {any} payment_idx
     * @param {any} transaction_type
     */
    const FnView = (payment_idx, transaction_type) => {
        if (transaction_type === 0) {
            navigate('/SiteAdmin/_pay/transactionHistoryView?idx=' + payment_idx + '&transaction_type=' + transaction_type);
        } else if (transaction_type === 1) {
            navigate('/SiteAdmin/_pay_terminal/transactionHistoryView?idx=' + payment_idx + '&transaction_type=' + transaction_type);
        }
    }

    const showContextMenu = (event, record) => {
        event.preventDefault();
        setContextMenu({
            visible: true,
            x: event.clientX, // 마우스 커서의 X 좌표
            y: event.clientY, // 마우스 커서의 Y 좌표
            record,
        });
    };

    // 컴포넌트 마운트 시 첫 데이터 로드
    useEffect(() => {
        fetchData(pagination.current, pagination.pageSize, sorter.field, sorter.order);
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setTableHeight(window.innerHeight - 200); // 200px 여백 고려
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);



    // ****************************************************

    function getManagerList1() {

        let bodyPage = {
            user_level: 2,
        }
        axios.post('/api/management/manager_list', bodyPage).then(function (res) {
            setManagerList1(res.data);

        })
    }

    const fn_submit = (e) => {
        $("#pg").val("1");
    }

    useEffect(() => {
        getManagerList1()      
    }, [])

    useEffect(() => {
        set_parent_idx1(q_parent_idx1);
    }, [q_parent_idx1])

   
    function rendManagerList() {
        return (
            <select name="parent_idx1" id="parent_idx1" onChange={on_set_parent_idx1_handler} value={parent_idx1}  >
                <option value=''>지사선택</option>
                {
                    managerList1.map((v, index) => {
                        return (
                            <option value={v.idx} key={v.idx} >{v.branch_name}</option>
                        )
                    })
                }
            </select>
        )
    }

    return (
        <>
            <div id="wrap">
                <Header />


                <div id="container" data-menu-page="pay_error">
                    <h3 className="page_ttl">거래거절(실패)내역</h3>
                    <form name="" id="" action="./payErrorList" method="get" onSubmit={fn_submit} >
                        <input type="hidden" name="pg" id="pg" value={page} />

                        <div className="top_filter">

                            <div className="date_box datepick_box">
                                <input type="hidden" name="sdate" id="sdate" value={(startDate === null || startDate === "") ? '' : new Date(startDate.toString().split('GMT')[0] + ' UTC').toISOString().slice(0, 10)} />
                                <input type="hidden" name="edate" id="edate" value={(endDate === null || endDate === "") ? '' : new Date(endDate.toString().split('GMT')[0] + ' UTC').toISOString().slice(0, 10)} />

                                <div className="datepick">
                                    <DatePicker locale={ko} dateFormat={'yyyy-MM-dd'} selected={startDate} onChange={(date) => setStartDate(date)} />
                                </div>
                                <span>-</span>
                                <div className="datepick">
                                    <DatePicker locale={ko} dateFormat={'yyyy-MM-dd'} selected={endDate} onChange={(date) => setEndDate(date)} />
                                </div>
                            </div>

                            <div className="sel_box">
                                {
                                    rendManagerList()
                                }
                            </div>

                            <div className="sel_box">
                                <select name="searchCates" id="searchCates">
                                    <option value="amount">금액</option>
                                    <option value="cardno">카드번호(뒤4자리)</option>
                                    <option value="cpid">터미널코드</option>
                                    <option value="St_branch_name">가맹점명</option>
                                </select>
                            </div>

                            <div className="in_box search_box">
                                <input type="text" name="searchWord" id="searchWord" placeholder='검색해주세요' />
                                <button type="submit" className="btn_st_02 blue search">검색</button>
                            </div>

                        </div>
                    </form>

                    <div className="bot_item">
                        <div className="bot_info">
                            <div className="left">총 {pagination.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}개</div>
                        </div>

                        <div className="table_box">
                            <div ref={tableRef} style={{ position: "relative" }}>
                                <Table

                                    columns={columns}
                                    dataSource={listData}
                                    onRow={(record) => ({
                                        onContextMenu: (event) => showContextMenu(event, record),
                                    })}


                                    rowKey="idx"
                                   
                                    pagination={{
                                        current: pagination.current,
                                        pageSize: pagination.pageSize,
                                        total: pagination.total,
                                    }}
                                    loading={loading}
                                    onChange={handleTableChange}
                                    scroll={{ y: tableHeight }}
                                    locale={{
                                        emptyText: <Empty description="데이터가 없습니다" style={{ minWidth: '2200px' }} />,
                                    }}
                                />

                                <ContextMenu
                                    x={contextMenu.x}
                                    y={contextMenu.y}
                                    visible={contextMenu.visible}
                                    onClose={() => setContextMenu({ ...contextMenu, visible: false })}
                                    record={contextMenu.record} // 선택된 행의 데이터 전달
                                />
                            </div>
                        </div>

                        
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )


}
export default SiteAdminPayPayErrorList