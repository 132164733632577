/* eslint-disable react-hooks/exhaustive-deps */
import * as axios from 'axios';
import React, { useEffect, useState, useRef } from 'react'
import * as XLSX from 'xlsx'
import { Table } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../include/SiteAdminHeader";
import Footer from "../../include/SiteAdminFooter";
import PagingNew from "../../include/PagingNew";
import queryString from 'query-string';
import { PgCardName, PgName, ChkDelayAble, GetPayGubun, GetPaymenttype, GetTransactionType } from "../../include/ConfigSet";
import { maskingFunc } from "../../include/ConfigSet_masking";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/esm/locale";
import re_closebtn_w from '../../../../assets/img/common/re_closebtn_w.png';
import OpenLogComment from "../../include/OpenLogComment";
import $ from 'jquery';
import "./styles.css"; // CSS 스타일 추가

/*******************************************
 * 매출집계
 *******************************************/
function SiteAdminPayAllPayTotalList() {
    const navigate = useNavigate();


    // 게시판 내역
    const [viewContent, setViewContent] = useState([]);

    // 검색 카테고리
    const [searchCates, setSearchCates] = useState('branch_name');
  
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");


    const [parent_idx2, set_parent_idx2] = useState("");


    const qs = queryString.parse(window.location.search);
    const sWord = qs.searchWord ? qs.searchWord : '';
    $("#searchWord").val(sWord);

    const sCate = qs.searchCates ? qs.searchCates : 'authno';
    $("#searchCates").val(sCate);

    const pg_group = qs.pg_group ? qs.pg_group : '';
    $("#pg_group").val(pg_group);

    const paymenttype = qs.paymenttype ? qs.paymenttype : '';
    $("#paymenttype").val(paymenttype);

    const ordertype = qs.ordertype ? qs.ordertype : '';
    $("#ordertype").val(ordertype);

    const chkdate = qs.chkdate ? qs.chkdate : 'authdate';
    $("#chkdate").val(chkdate);

    const sdate = qs.sdate ? qs.sdate : '';
    $("#sdate").val(sdate);

    const edate = qs.edate ? qs.edate : '';
    $("#edate").val(edate);

    const q_parent_idx1 = qs.parent_idx1 ? qs.parent_idx1 : '';
    const q_parent_idx2 = qs.parent_idx2 ? qs.parent_idx2 : '';

    const status = qs.status ? qs.status : '';
    $("#status").val(status);


    const viewType = qs.viewType ? qs.viewType : 'store';
    $("#viewType").val(viewType);


    const dateType = qs.dateType ? qs.dateType : 'during';
    $("#dateType").val(dateType);

    const page = qs.pg ? qs.pg : 1;


    /**
     * 
     * @param {{nums:number,regdate:string}} nums 게시판 키값
     * @param {*} regdate 등록일자
     * @param {*} branch_name 지사명
     * @param {string} representative 대표자명
     * @param {string} user_id ID
     * @param {string} corp_class 구분
     * @param {string} calculate 정산Type
     * @param {string} commission 제공수수료
     * @param {string} contract_status 상태
     * @param {string} login_able 로그인 허용여부
     * @returns 
     */

    // 매출내역 - 건수 합계
    let total_BE_cnt = 0;

    // 매출내역 - 금액 합계
    let total_BE_amount = 0;


    // 정산전 취소 - 건수 합계
    let total_BC_cnt = 0;

    // 정산전 취소 - 금액 합계
    let total_BC_amount = 0;


    // 정산후 취소 - 건수 합계
    let total_EC_cnt = 0;

    // 정산후 취소 - 금액 합계
    let total_EC_amount = 0;

    // 금액 합계
    let total_total_amount = 0;

    // PG입금 합계
    let total_pg_income = 0;

    // 가맹입금 합계
    let total_st_income = 0;

    // 가맹점 합계
    let total_st_fee = 0;

    // PG 합계
    let total_pg_fee = 0;

    // 본사 합계
    let total_admin_fee = 0;

    // 본사 합계
    let total_mbramch_fee = 0;

    // 본사 합계
    let total_distributor_fee = 0;



    let tmp_name = "";



    const Board1 = ({
        chk_date,
        sell_cnt,
        st_name,
        branch_name,
        Distributor_name,
        BE_cnt,
        BE_amount,
        BC_cnt,
        BC_amount,
        EC_cnt,
        EC_amount,
        total_amount,
        pg_income,
        st_income,
        st_fee,
        pg_fee,
        admin_fee,
        mbramch_fee,
        distributor_fee
    }) => {
        if (viewType == "store") {
            tmp_name = st_name;

        } else if (viewType == "mbramch") {
            tmp_name = branch_name;

        } else if (viewType == "Distributor") {
            tmp_name = Distributor_name;

        }

        if (!pg_income) {
            pg_income = 0;
        }

        if (!pg_fee) {
            pg_fee = 0;
        }


        return (
            <>
                <tr>
                    {(dateType === "daily" ? <td>{chk_date}</td> : "")}
                    <td>{tmp_name}</td>
                    <td>{BE_cnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                    <td>{BE_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                    <td className="orange">{BC_cnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                    <td className="orange">{BC_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                    <td className="red">{EC_cnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                    <td className="red">{EC_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                    <td className="total_amount_td">{total_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                    <td>{pg_income.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                    <td>{st_income.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                    <td className="st_fee_td">{st_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                    <td>{pg_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                    <td>{admin_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                    <td>{mbramch_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                    <td>{distributor_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                </tr>
            </>
        );

    };


    useEffect(() => {


        if (sCate) {
            setSearchCates(sCate);
        }

        if (sdate !== "") {
            setStartDate(new Date(sdate));
        }

        if (edate !== "") {
            setEndDate(new Date(edate));
        }


    }, [sWord, sCate]);
    /*
    pg, sWord
    */


    useEffect(() => {

        const loginId = sessionStorage.getItem("loginId");

        let bodyPage = {        
            searchWord: sWord,
            searchCates: sCate,
            user_id: loginId,
            pg_group: pg_group,
            paymenttype: paymenttype,
            ordertype: ordertype,
            chkdate: chkdate,
            sdate: sdate,
            edate: edate,
            parent_idx1: q_parent_idx1,
            parent_idx2: parent_idx2,
            status: status,
            viewType: viewType,
            dateType: dateType

        }
        //console.log("bodyPage : " + bodyPage);

        axios.post('/api/manage/pay_all/pay_total_list', bodyPage).then(function (res) {
            //console.log(pg + " -> " + nPage + " -> " + pageMax + " -> " + url);
            setViewContent(res.data);
            //console.log(res.data)
        })




    }, [sWord, sCate, page]);
    // nums

    const fn_submit = (e) => {
        $("#pg").val("1");
    }

    function ListView() {
        
        return (
            <>
                <div className="bot_item">
                    <div className="bot_info">

                    </div>

                    <div className="table_box">
                        <table>
                            <thead>
                                <tr>

                                    {(dateType === "daily" ? <th rowSpan='2'>일자</th> : "")}
                                    <th rowSpan="2">
                                        {(viewType === "store" ? "가맹점별" : (viewType === "mbramch" ? "지사별" : "총판별"))}
                                    </th>
                                    <th colSpan="2" className="th_top left_line">매출내역</th>
                                    <th colSpan="2" className="th_top left_line">정산전 취소</th>
                                    <th colSpan="2" className="th_top left_line">정산후 취소</th>
                                    <th colSpan="8" className="th_top left_line">매출집계</th>
                                </tr>
                                <tr>
                                    <th className="left_line">건수</th>
                                    <th>금액</th>
                                    <th className="left_line">건수</th>
                                    <th>금액</th>
                                    <th className="left_line">건수</th>
                                    <th>금액</th>
                                    <th className="left_line">금액</th>
                                    <th>PG입금</th>
                                    <th>가맹입금</th>
                                    <th>가맹점</th>
                                    <th>PG</th>
                                    <th>본사</th>
                                    <th>지사</th>
                                    <th>총판</th>
                                </tr>
                            </thead>

                            <tbody>

                                {
                                    viewContent.map((v, index) => {

                                        let pg_income = 0;
                                        if (v.pg_income) {
                                            pg_income = v.pg_income;
                                        }

                                        let pg_fee = 0;
                                        if (v.pg_fee) {
                                            pg_fee = v.pg_fee;
                                        }

                                        // 매출내역 - 건수 합계
                                        total_BE_cnt = parseInt(total_BE_cnt) + parseInt(v.BE_cnt);

                                        // 매출내역 - 금액 합계
                                        total_BE_amount = parseInt(total_BE_amount) + parseInt(v.BE_amount);

                                        // 정산전 취소	 - 건수 합계
                                        total_BC_cnt = parseInt(total_BC_cnt) + parseInt(v.BC_cnt);

                                        // 정산전 취소	 - 금액 합계
                                        total_BC_amount = parseInt(total_BC_amount) + parseInt(v.BC_amount);


                                        // 정산후 취소	 - 건수 합계
                                        total_EC_cnt = parseInt(total_EC_cnt) + parseInt(v.EC_cnt);

                                        // 정산후 취소	 - 금액 합계
                                        total_EC_amount = parseInt(total_EC_amount) + parseInt(v.EC_amount);


                                        // 금액	 - 합계
                                        total_total_amount = parseInt(total_total_amount) + parseInt(v.total_amount);

                                        // PG입금	 - 합계
                                        total_pg_income = parseInt(total_pg_income) + parseInt(pg_income);

                                        // 가맹입금	 - 합계
                                        total_st_income = parseInt(total_st_income) + parseInt(v.st_income);

                                        // 가맹점	 - 합계
                                        total_st_fee = parseInt(total_st_fee) + parseInt(v.st_fee);

                                        // PG	 - 합계
                                        total_pg_fee = parseInt(total_pg_fee) + parseInt(pg_fee);

                                        // 본사	 - 합계
                                        total_admin_fee = parseInt(total_admin_fee) + parseInt(v.admin_fee);

                                        // 지사	 - 합계
                                        total_mbramch_fee = parseInt(total_mbramch_fee) + parseInt(v.mbramch_fee);

                                        // 총판	 - 합계
                                        total_distributor_fee = parseInt(total_distributor_fee) + parseInt(v.distributor_fee);



                                        return (
                                            <Board1
                                                key={v.idx}
                                                st_name={v.st_name}
                                                branch_name={v.branch_name}
                                                Distributor_name={v.Distributor_name}
                                                chk_date={v.chk_date}
                                                sell_cnt={v.sell_cnt}
                                                BE_cnt={v.BE_cnt}
                                                BE_amount={v.BE_amount}
                                                BC_cnt={v.BC_cnt}
                                                BC_amount={v.BC_amount}
                                                EC_cnt={v.EC_cnt}
                                                EC_amount={v.EC_amount}
                                                total_amount={v.total_amount}
                                                pg_income={v.pg_income}
                                                st_income={v.st_income}
                                                st_fee={v.st_fee}
                                                pg_fee={v.pg_fee}
                                                admin_fee={v.admin_fee}
                                                mbramch_fee={v.mbramch_fee}
                                                distributor_fee={v.distributor_fee}
                                            />
                                        );
                                    })
                                }

                                <tr className="total">
                                    {(dateType === "daily" ? <td colSpan='2'>TOTAL</td> : <td>TOTAL</td>)}

                                    <td>{total_BE_cnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td>{total_BE_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td>{total_BC_cnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td>{total_BC_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td>{total_EC_cnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td>{total_EC_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td>{total_total_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td>{total_pg_income.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td>{total_st_income.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td>{total_st_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td>{total_pg_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td>{total_admin_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td>{total_mbramch_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td>{total_distributor_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                </tr>
                            </tbody>

                        </table>
                    </div>

                </div>
            </>
        )





    }


    return (
        <>
            <div id="wrap">
                <Header />



                <div id="container" data-menu-page="pay_total">
                    <h3 className="page_ttl">매출집계</h3>

                    <form name="" id="" action="./pay_total" method="get" onSubmit={fn_submit} >
                        <input type="hidden" name="pg" id="pg" value={page} />

                        <div className="top_filter">

                            <div className="date_box datepick_box">
                                <input type="hidden" name="sdate" id="sdate" value={(startDate === null || startDate === "") ? '' : new Date(startDate.toString().split('GMT')[0] + ' UTC').toISOString().slice(0, 10)} />
                                <input type="hidden" name="edate" id="edate" value={(endDate === null || endDate === "") ? '' : new Date(endDate.toString().split('GMT')[0] + ' UTC').toISOString().slice(0, 10)} />

                                <div className="datepick">
                                    <DatePicker locale={ko} dateFormat={'yyyy-MM-dd'} selected={startDate} onChange={(date) => setStartDate(date)} />
                                </div>
                                <span>-</span>
                                <div className="datepick">
                                    <DatePicker locale={ko} dateFormat={'yyyy-MM-dd'} selected={endDate} onChange={(date) => setEndDate(date)} />
                                </div>
                            </div>

                            <div className="sel_box">
                                <select name="dateType" id="dateType" >
                                    <option value="during">기간합</option>
                                    <option value="daily">일자합</option>

                                </select>
                            </div>

                            <div className="sel_box">
                                <select name="viewType" id="viewType">
                                    <option value="store">가맹점명보기</option>
                                    <option value="mbramch">지사별보기</option>
                                    <option value="Distributor">총판별보기</option>

                                </select>
                            </div>



                            <div className="in_box search_box">
                                <button className="btn_st_02 blue search" style={{ 'right': 'initial' }}>검색</button>
                            </div>

                        </div>
                    </form>



                    <ListView />



                </div>

            </div>

            <Footer />
        </>
    )
}

export default SiteAdminPayAllPayTotalList